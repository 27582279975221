//import allowlist from './allowlist.js';

import React, { Component } from 'react';
import BoonkbotsGenesis from '../abis/BoonkBots.json'
import BoonkbotsSunrise from '../abis/BoonkbotsSunrise.json'
//import BoonkbotsTrax from '../abis/Boonktrax.json'

import $ from 'jquery';
import { createContractHelper } from "./createContractHelper";
import { resolveProvider } from "./resolveProvider";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './home.js'
import Navigation from './navbar.js'
import Footer from './footer.js'
import Boonktrax from './boonktrax.js'
import Boonkwear from './boonkwear.js'
import Genesis from './genesis.js'
import Sunrise from './sunrise.js'
import Vip from './vip.js'
import Claim from './claim.js'

//const CONTRACT_ADDRESS = "0xBf56cE163211E881D4470fe58819b94af60091aF"
//const CHAIN_ID = '0x4' //rinkeby
//const CHAIN_ID = "0x539" // ganache


// var launchdate = 1663790400000 // 21-09-2022 - 22h
// var now = new Date().getTime()
// let Home
// if(now > launchdate){
//     Home = Home_soldout
// } else {
//     Home = Home_public
// }

const sunrise_address = "0x9640ecf1Ae2E16D8543b008d1c90B19F2232eE4a"
const genesis_address = "0x3A6b8f7337112cE51282094f60aC10C8682C30a7"
//const trax_address = "0xa028e19f8fb745f614f8917413484fe87d98004c"
const CHAIN_ID = '0x1' //live

const provider = resolveProvider("main")
const boonkbots_sunrise = createContractHelper(sunrise_address, BoonkbotsSunrise.abi, provider)
const boonkbots_genesis = createContractHelper(genesis_address, BoonkbotsGenesis.abi, provider)
//const boonkbots_trax = createContractHelper(trax_address, BoonkbotsGenesis.abi, provider)

class App extends Component {

  async loadSupply() {

    let totalSupply = await boonkbots_sunrise.reader.totalSupply();
    totalSupply = parseInt(totalSupply);
    this.setState({totalSupply : totalSupply});

  }

  async componentWillMount() {

    let status
    if (typeof window.ethereum == 'undefined') {
      status = 0
    }
    else {
      status = 1

      // load supply
      //this.loadSupply();
    }

    this.setState(
        {status : status}
      );
  }


  async requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: CHAIN_ID }],
    });
    const address = await provider.getSigner().getAddress()
    console.log(address);
    this.setState({address : address});
    if (this.state.address !== "") {
      console.log(this.state.address)
      $("#connectbutton").hide();
      $("#account").html(this.state.address);
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      address: "",
      status: null,
      connected: false,
      contract: null,
      totalSupply: 568,
      boonkbots: [],
      owners: [],
      tokenuris: [],
      balanceOf: 0,
      myBoonkbots: [],
      status_allowed: 1,
      page: "home"
    }
  }

  render() {
    return (
      <BrowserRouter>
      <div className="global">
        <Navigation state={this.state} CHAIN_ID={CHAIN_ID} provider={provider}/>
         <div className="container">
          <Routes>
            <Route path="/" element={<Home state={this.state} CHAIN_ID={CHAIN_ID} provider={provider} boonkbots={boonkbots_sunrise}/>} exact/>
            <Route path="/boonktrax" element={<Boonktrax/>} exact/>
            <Route path="/genesis" element={<Genesis/>} exact/>
            <Route path="/sunrise" element={<Sunrise />} exact/>
            <Route path="/vip" element={<Vip state={this.state} CHAIN_ID={CHAIN_ID} provider={provider} boonkbots_sunrise={boonkbots_sunrise} boonkbots_genesis={boonkbots_genesis} />} exact/>
            <Route path="/boonkwear" element={<Boonkwear/>} exact/>
            <Route path="/claim" element={<Claim state={this.state} CHAIN_ID={CHAIN_ID} provider={provider} boonkbots={boonkbots_sunrise}/>} exact/>
          </Routes>



           <Footer />
         </div>
       </div>
       </BrowserRouter>
    );
  }
}

export default App;
