import React, { Component } from 'react';
import $ from 'jquery';
import { ethers } from 'ethers'
import {  genesis_meta, sunrise_meta  }  from './metadata.js'


class GenesisAssets extends Component {

constructor(props) {
  super(props);
  this.state = {
    status: '',
    address: ''
  };
}

async componentWillMount() {

}

renderMeta(id) {

  let ks = Object.keys(genesis_meta)
  let i = ks.indexOf(id.toString())
  let name = Object.values(genesis_meta)[i][1]
  let rarity = Object.values(genesis_meta)[i][0]
  return([name, rarity])
}


render() {
    return(
      <div className="mb-5 mt-5">
      <hr/><br/>
          <div className="row mb-5 metadata">
            <div className="col text-left">
            <span class="magenta">Name:</span> {this.renderMeta(this.props.id)[0]}<br/>
            <span class="magenta">rarity</span> {this.renderMeta(this.props.id)[1]}<br/>
              <span class="magenta">Series:</span> B00NKB0TS G3N3S1S<br/>
            <span class="magenta">Nr:</span> {this.props.id.toString().padStart(3, '0')}<br/>

              <small><a href={"https://opensea.io/assets/ethereum/0x3a6b8f7337112ce51282094f60ac10c8682c30a7/"+this.props.id.toString()} target="_blank" rel="noopener noreferrer" >view on opensea</a></small>
            </div>
          </div>

        <div className="row">
           <div className="col ">
              <div className="vid">
                 <video src={"https://assets.boonkbots.xyz/genesis/mp4_400/" + this.props.id.toString().padStart(5, '0') + "_boonkbot.mp4"} muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop  controls controlsList="nodownload"> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
              </div>
              <span className="click"><a href={"https://assets.boonkbots.xyz/genesis/mp4/" + this.props.id.toString().padStart(5, '0') + "_boonkbot.mp4"} target="_blank" rel="noopener noreferrer" >download HD MP4</a></span>
           </div>
           <div className="col">
             <img class="pfp" src={"https://assets.boonkbots.xyz/genesis/pfp_500/" + this.props.id.toString().padStart(5, '0') + "_boonkbot_pfp.png"}/>
             <div className="mt-2">
             <span className="click"><a href={"https://assets.boonkbots.xyz/genesis/pfp/" + this.props.id.toString().padStart(5, '0') + "_boonkbot_pfp.png"} target="_blank" rel="noopener noreferrer">download HD PFP</a></span>
             </div>
           </div>
        </div>
      </div>
    )
  }
}


class SunriseAssets extends Component {

constructor(props) {
  super(props);
  this.state = {
    status: '',
    address: ''
  };
}

async componentWillMount() {


}

renderMeta(id) {

  let ks = Object.keys(sunrise_meta)
  let i = ks.indexOf(id.toString())
  let name = Object.values(sunrise_meta)[i][1]
  let rarity = Object.values(sunrise_meta)[i][0]
  return([name, rarity])
}

render() {
    return(
      <div className="mb-5 mt-5 vipcomponent">
      <hr/><br/>
          <div className="row mb-5">
            <div className="col text-left metadata">
            <span class="magenta">Name:</span> {this.renderMeta(this.props.id)[0]}<br/>
            <span class="magenta">rarity</span> {this.renderMeta(this.props.id)[1]}<br/>
              <span class="magenta">Series:</span> B00NKB0TS SUNR1S3<br/>
            <span class="magenta">Nr:</span> {this.props.id.toString().padStart(3, '0')}<br/>
            <small><a href={"https://opensea.io/assets/ethereum/0x9640ecf1ae2e16d8543b008d1c90b19f2232ee4a/"+this.props.id.toString()} target="_blank" rel="noopener noreferrer" >view on opensea</a></small>

            </div>
          </div>

        <div className="row">
           <div className="col ">
              <div className="vid">
                 <video src={"https://assets.boonkbots.xyz/sunrise/mp4_400/" + this.props.id.toString().padStart(5, '0') + "_boonkbot.mp4"} muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop controls controlsList="nodownload"> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
              </div>
              <span className="click"><a href={"https://assets.boonkbots.xyz/sunrise/mp4/" + this.props.id.toString().padStart(5, '0') + "_boonkbot.mp4"} target="_blank" rel="noopener noreferrer" >download HD MP4</a></span>
           </div>
           <div className="col">
             <img class="pfp" src={"https://assets.boonkbots.xyz/sunrise/pfp_500/" + this.props.id.toString().padStart(5, '0') + "_boonkbot_pfp.png"}/><br/>
             <div className="mt-2">
             <span className="click"><a href={"https://assets.boonkbots.xyz/sunrise/pfp/" + this.props.id.toString().padStart(5, '0') + "_boonkbot_pfp.png"} target="_blank" rel="noopener noreferrer" >download HD PFP</a></span>
             </div>
           </div>
        </div>
      </div>
    )
  }
}

class BoonkpackAssets extends Component {

constructor(props) {
  super(props);
}

render() {
    return(
      <div className="mb-5 mt-5 vipcomponent">
      <hr/><br/>
          <div className="row mb-5">
            <div className="col text-left metadata">
            <span class="magenta">Name:</span> B00NKP4CK 01<br/>
            <span class="magenta">Description:</span> l33T audio loops to make your own B00NK. Available for all B00NKH34DZ.<br/>

            </div>
          </div>

        <div className="row">
           <div className="col ">
              <div className="vid">
                <img class="pfp" src="https://assets.boonkbots.xyz/pack/B00NKP4CK_01.jpg"/>
              </div>
              <span className="click"><a href="https://assets.boonkbots.xyz/pack/B00NKP4CK_01.zip" target="_blank" rel="noopener noreferrer" >download sample pack</a></span>
           </div>
        </div>
      </div>
    )
  }
}

class Hub extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: '',
      address: '',
      genesis: [],
      sunrise: [],
      trax: []
    };
  }

  async requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: this.props.CHAIN_ID }],
    });
    const address = await this.props.provider.getSigner().getAddress()
    this.setState({address : address});
    console.log("address: ", address);
    this.updateState();
  }

  async componentWillMount() {

    $('body').removeClass().addClass('genesis');

    this.updateState();


  }


  async updateState() {

    let status
    if (typeof window.ethereum == 'undefined') {

      // no metamask
      status = 0
      console.log("metamask not installed")

    } else {

      //this.loadSupply();

      if (!this.state.address) {

        // not connected
        status = 1
        console.log("not connected")

      } else if (this.state.address === "") {

        // not connected
        status = 1
        console.log("not connected")

      } else {

        // connected
        status = 2
        console.log("connected and eligible")

      }
    }

    console.log("status: ", status)
    this.setState(
        {status : status},
        function () {
          this.renderConnectText()
        }
      );

  }


  async loadGenesis() {

    console.log("loading genesis");
    console.log("address: ", this.state.address);
    let walletOfOwner = await this.props.boonkbots_genesis.reader.walletOfOwner(this.state.address);
    walletOfOwner = walletOfOwner.map(x => x.toNumber());
    walletOfOwner = walletOfOwner.sort(function (a, b) {  return a - b;  });
    this.setState({genesis : walletOfOwner});
    console.log("wallet genesis: ", this.state.genesis);

  }

  async loadSunrise() {

      let boonkbots_sunrise = this.props.boonkbots_sunrise.reader
      let address = this.state.address
      console.log(await boonkbots_sunrise.name(), 'tokens owned by', address);

      const sentLogs = await boonkbots_sunrise.queryFilter(
        boonkbots_sunrise.filters.Transfer(address, null),
      );
      const receivedLogs = await boonkbots_sunrise.queryFilter(
        boonkbots_sunrise.filters.Transfer(null, address),
      );

      const logs = sentLogs.concat(receivedLogs)
        .sort(
          (a, b) =>
            a.blockNumber - b.blockNumber ||
            a.transactionIndex - b.TransactionIndex,
        );

      const owned = new Set();

      for (const log of logs) {
        const { from, to, tokenId } = log.args;

        if (ethers.utils.getAddress(to) === ethers.utils.getAddress(address)) {
          owned.add(tokenId.toString());
        } else if (ethers.utils.getAddress(from) === ethers.utils.getAddress(address)) {
          owned.delete(tokenId.toString());
        }
      }

      let owned_array = Array.from(owned);
      owned_array = owned_array.map(Number);
      this.setState({sunrise : owned_array});
      console.log("wallet sunrise: ", this.state.sunrise);
  }

  async loadBoonktrax() {

  }

  renderGenesisAssets() {

    let assets = []
    for (var i = 0; i < this.state.genesis.length; i++) {

      assets.push( <GenesisAssets id={this.state.genesis[i]}/> );

     }
     return(assets)
  }

  renderSunriseAssets() {

    let assets = []
    for (var i = 0; i < this.state.sunrise.length; i++) {

      assets.push( <SunriseAssets id={this.state.sunrise[i]}/> );

     }
     return(assets)
  }

  renderBoonkpackAssets() {
    let html
    if ((this.state.genesis.length + this.state.sunrise.length) == 0) {
      html = ""
    } else {
      html = <BoonkpackAssets/>
    }
     return(html)
  }


  renderConnectText() {

    let status = this.state.status
    console.log(status)

    switch (status) {
      case 0:
        $("#metamask_install").show();
        $("#connectbutton_submit").prop('disabled', true)
        $("#connectbutton_body").css('cursor', 'not-allowed')
        $("#claim_address").hide();
        $("#welcome").hide();
        break;
      case 1:
        $("#metamask_install").hide();
        $("#mintbutton").hide()
        $("#minted").show()
        $("#claim_address").hide();
        $("#welcome").hide();
        break;
      case 2:
        $("#metamask_install").hide();
        $("#mintbutton").show()
        $("#minted").hide()
        $("#connectbutton_body").hide();
        $("#claim_address").show();
        $("#headline").hide();
        $("#welcome").hide();
        break;
      default:
        $("#metamask_install").show();
    }
  }

  render() {
    return (



    <div id="hub" >
    <div className="maincontent d-flex align-items-center justify-content-center">

    <div className="">

      <div id="sunrise_header">
        <div className="centered">
            <div className="row">
               <div className="col">
                  <span>
                     <h1 className="text-responsive magenta bloom_mag">V1P</h1>
                  </span>
               </div>
             </div>
         </div>
      </div>


    <div id = "headline">
      <h5><br/><br/><span>view and download your precious boonkbots.</span></h5>
    </div>

    <div className="row mt-5">
       <div className="col">

          <span id="claim_address">

          ~ {this.state.address}

          <br/>  <br/>

          {
            (this.state.genesis.length + this.state.sunrise.length) == 0 ?
              <div id="welcome"><span className="magenta">Sorry anon</span>, this wallet doesn't contain any B00NKB0TS.</div>
            :
              <div id="welcome">Welcome to the V1P, anon. <br/><span className="magenta">scroll down</span> to view your boonkbots.</div>
          }





          </span>

       </div>
    </div>

      <div id="connectbutton_body" className="row mt-5">
        <main role='main' className='col-lg-12 d-flex text-center'>
          <div className='content mr-auto ml-auto'>
            <form onSubmit={async (event) => {
              console.log("test");
              event.preventDefault();
              await this.requestAccount();
              await this.loadGenesis();
              await this.loadSunrise();
              await this.loadBoonktrax();
              $("#welcome").show();
            }}>
              <input
                id="connectbutton_submit"
                type='submit'
                className='btn btn-block btn-primary l3372'
                value='connect to wallet'
              />
            </form>
          </div>
        </main>
      </div>


          {/*
         <div className='row' id="minted">
            <div className='col pt-2'>
            <h6><span className = "magenta flicker_magenta">&nbsp;{this.state.max_supply - this.state.totalSupply}&nbsp;</span> / <span className = "magenta">&nbsp;{this.state.max_supply}&nbsp;</span></h6>
            available
            </div>
         </div>
         */}
         <div className='row' id="metamask_install">
            <div className='col pt-2'>
               <h6><span>install <a href='https://metamask.io/' target='_blank' rel='noopener noreferrer'>metamask</a> to mint</span>.</h6>
            </div>
         </div>




      </div>


         </div>

         <div id="genesis_assets">{this.renderGenesisAssets()}</div>
         <div id="sunrise_assets">{this.renderSunriseAssets()}</div>
         <div id="boonkpack_assets">{this.renderBoonkpackAssets()}</div>


            </div>
)
}}

export default Hub;
