import {ethers} from "ethers";

export function resolveProvider(net) {

  let provider
  if (typeof window.ethereum !== 'undefined') {

    if (net==="main") {
      provider = new ethers.providers.Web3Provider(window.ethereum);
    } else if (net==="local") {
      //provider = new ethers.providers.Web3Provider(window.ethereum, "any");

      const url = "http://localhost:7545";
      provider = new ethers.providers.JsonRpcProvider(url);
      console.log("local mode")
    }


    return(provider)
  }


}
