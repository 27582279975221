import React, { Component } from 'react';

class Boonkwear extends Component {

  render() {
    return(
      <div>
      BOONKWEAR
      </div>
    )
  }
}

export default Boonkwear;
