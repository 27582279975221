import { ethers } from 'ethers'

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

let claimlist = {

"0xEFd231e555f158E00d98508A4b2508E2c82dA609": 36,
"0xd4B5f5F11C7bC904329D91A99FacDA25f10Fd709": 19,
"0xcbd4D61452B878969EEE1502d3Fb0FEADAc57F4b": 19,
"0xaC03FCc8fF62B3a3427ea2657CB4b78436C9Bc5F": 18,
"0xe8C326f44bfD432e541754f27F12113B052AC66D": 17,
"0x761a57f824f53dB8b5eDdaEdC5b888e1c62AaA3E": 16,
"0x149884b699FE3E1b042f85fA3B54843EB689b2d4": 16,
"0x346aa548f2fB418a8c398D2bF879EbCc0A1f891d": 16,
"0x93811dabfbeE9736035025cBa91491d7747cfD47": 13,
"0x3a17f9315B1344c17C69714b774B56C6565Ab70c": 10,
"0x8De71Cf7ef5ee27cB16DF36c66888B9AB14FFa85": 10,
"0xBdc1194b4A15437A93c3EDdA954140078fFff5B2": 10,
"0x5a9DC3F62B089aC07718aD23a0311E1e8283ba72": 9,
"0xd0D68bf506ccE28380DAe5aA9FB7c52913b3E2Ef": 7,
"0xFb469965eC425D5B83Da58dd75A3A77AC06eafa1": 7,
"0x38B9B365E78828D6324B1dE2eF7989C397EeFb89": 7,
"0x9aEA57d2CD5a1eaeCe84C6EFbBA0D960B7612838": 6,
"0xA43711F5DCc3559a1E07eC124fd167e26c9BaD43": 6,
"0x37CC0016418852a849C2E0cC29445d7403C810A0": 6,
"0xBF912CB4d1c3f93e51622fAe0bfa28be1B4b6C6c": 5,
"0x7e5161ebbB67e6D47D23F0c88Bc2552d1F76B478": 5,
"0x1eE4aEC751AB97A6f1f60b87c48e52387Eee91de": 4,
"0x282625BaF5589bf71c54118D9D6A006A1Ce8A06F": 4,
"0x3a906ea08dbfAE7848ceeb9a78b9eD52a39988e3": 4,
"0x2fa510caf46f88Ae7Cab8DaEc696aBBc299f8D20": 4,
"0xc1CEF46c74425A17C9021394B796642179C5bB98": 4,
"0x4748664b688569c2711E9715B5e42fDdF15E38Df": 4,
"0x55D3225ae380B3e3c8896bcAA85F35EAC22E3beC": 4,
"0x883Ef866F34F5b437Ea0e845d0fACb635a1B93d3": 4,
"0xC0F9e8De7cE5ad40979de08b6b746BA47Ca608cC": 4,
"0x41fCb82b1f4E18c717A6577C87A7AE316eA9A584": 4,
"0x2e4781EdB9E8D233E9b6D970E162f727fA0FC8e0": 4,
"0xC8c6C74CFfBa0DB57fAAFaa511d85d386DE02765": 4,
"0x05d9FDD098a0BA064C8296505febc6769f623EF0": 4,
"0x881475210E75b814D5b711090a064942b6f30605": 4,
"0xAB5f9071D325b9B5844D52cD4e55ea6bbaCeB021": 4,
"0xe4C074526e13a51DC70Ff88C3934b64dc714A4FE": 4,
"0x7499Fb8bc7Bf63c654c435AEFFC33e17c686387f": 4,
"0x1593110441AB4C5F2C133F21B0743B2b43E297cB": 4,
"0x67a49a456Bc5d72f8670dDA8bD8efa83c8691e01": 4,
"0x5Ccd8ac57FA61Cf9aF094667625A7c4fBEBDe75F": 4,
"0x8D5b48934c0C408ADC25F14174c7307922F6Aa60": 4,
"0xE87970A2479167790339AeCE727A58D1FF89323D": 4,
"0x3e5c680b3026530ca38500509f474304DbBf6a6F": 4,
"0xF2F5c5A315E56ceE6587e862BC7f74152F6467e2": 4,
"0xa698A0adFfDF132e3f8F35f3C89c0B9623835090": 4,
"0x8D3bea823f22FBfe058577cA01E9a88160E2dd3A": 4,
"0x5bE0CC83866Fcc0DF3591C06593B5aEC4F439463": 4,
"0xb8be5256EdcD6C9Da1A0F74B615A2f9403382730": 4,
"0x13FEED6B23Be320B4097559c942E16F37e159051": 4,
"0xc612E41Fd7aa14703bD29ae99221B00A67C72Aec": 4,
"0x98ca9C5F410B530f87C4Bfd7Becea177d9b889C9": 4,
"0xb84D333bE1Cc7D7D3DF8A7Dc57C97ac8129cB514": 3,
"0xF29A0B06BB927f6A58C6f3bBA40c88cF305Aacc7": 3,
"0x69F40ae0e91845Ac3bdB1Cf80d09d662AFD30576": 3,
"0xa2F0448f346cE50B9029506c88Dfa58d07bAF880": 3,
"0x63cbd8b41B83D42Ce303F21872Ff51553EB4B9C6": 3,
"0xF06fB2B34bB00D1428D4bae2B1D749bBa399cC98": 3,
"0x4c84f2c943b25bA1fD4ACa82244A5b00D90a1AD5": 3,
"0x59a847CcfF6a15be1b76069aA6efF7B4Bd93bdB7": 2,
"0x75256A46E98Eb0F9c4eefc197eb3DD88d559a771": 2,
"0x41cbe54cCdcCa91177cb8C1cd0bC8E1D86251788": 2,
"0x27829CCb3c6a343dd8Fd94Bb68D82B7b2715F618": 2,
"0xea0Fdba156b3F9df82F2d7E39bfEceb247CC9F9d": 2,
"0x13EEe41d67b8d99E11174161D72cF8cCD194458C": 2,
"0xC31afe50abc2FC1a3Cb5B4d4A163738e595c8fEE": 2,
"0x27dcB67fe978CC420F8aC470dC22A0699d90da29": 2,
"0x8AaB6209128CDdc0acF4f7A24A52AD574F438d58": 2,
"0x000e7bE03F9AdBC5065396a09e1b6ddC9DA5A8bF": 2,
"0x61e193e514DE408F57A648a641d9fcD412CdeD82": 2,
"0x2cb9AE536fc474159b95dF01c5a708CAcD9F84B2": 2,
"0x3913d13611D2F128e3e1d3Ee7dAbA53e4d37a2C8": 2,
"0xf433bdbf45ae8396805F5882C9F395b246E62AF8": 2,
"0xdd023695Ba07D06A9db8B9d087C731A8fb288778": 2,
"0x373FB5D8A3E1320d5042Bdb4f0A4DfB007D56c9a": 2,
"0x4791bc59e8bF9a12095C5A83612932adfEad3809": 2,
"0x553d3D2Aa90580A0A4B3Ca8cd06246bEb9aCD3b8": 2,
"0x95db775Fce905ae78eBFdA1845470121208CeA39": 2,
"0x521b7C38d4Ec9038aF0b4eEACB1f5F113B1b1f0B": 2,
"0xB2F4b16595e02A9721F97E3E30FB5Bbbf73F5F54": 2,
"0xcA63b4Ac974D621AA4CCFE9E502740dC6F1903bD": 2,
"0x35Aff68bb4e0E584d501C73D8Eb2bA8aC5766373": 2,
"0xB675B284D6f7d59F5b03B9b4DA43D89B0D8A277D": 2,
"0x99Cc0DE7B4735c0A2aC9987F77820490313FF000": 2,
"0xEf6Fb636b00dF2D9E8F3B015c5ee8610298c4064": 2,
"0xeF90DD7379d0217Be1564a1D8AE45B467b56d039": 2,
"0x93807E8Fd6CE4529960d545E0cc9C4e988f300cC": 2,
"0x4Ed56079d2693f735eC079a58d6EcF16cdb9Ac33": 2,
"0xD56e53c1889E0ac25A6f7035419067dC7471d71F": 2,
"0x568092fb0aA37027a4B75CFf2492Dbe298FcE650": 2,
"0xb7c7517A89a969CF0603955C7ACaCFAB85E1C40a": 2,
"0x20d76D0A61ab86a36365a318b12168B4EfCA1310": 2,
"0xb1deF53BF2c8e45df2dEA0306CCf591ec0D8000E": 2,
"0x4cB780Fd3286f2d10199B61423D1c804BD2a5D2B": 2,
"0x30F9205EBa10D32A8e8DB62b7d0aC8e8Ad8ccb2f": 2,
"0x3a2Ea5595098565e7362e04dA30C5ec29435731F": 2,
"0x461AE8c33224ACeB7f1259095dd2a334Ad20f322": 2,
"0x67e4d3E1a80083fC957AbA529cebAea5426989e4": 2,
"0xc9DFecCfE5dB59933b760995c11242305B9cbCa6": 2,
"0xBEE5AB4aAdE195432631DdC7DEC15C51BdC21133": 2,
"0xbd2E0db33a588178eF11869C002Bd5C939224634": 2,
"0x198e363E2e7d58f521960e4175a7dfe0F59936f2": 2,
"0x12213A6c9A7dbfC1A737ea565933448f0843dEa7": 2,
"0xC3C49037e2a6d85894D04bf75F112968599df886": 2,
"0x24F2FC86c6087A82db87ceBF0e7DD632128ef27f": 2,
"0xc58470415723830b964a55ba355d1aC1e8735d3a": 2,
"0xD9b49a81ee72aF3C026a2C144C9Ffd678A78C8b1": 2,
"0x35bE279b2eF0004aFcf4Ce83C0B5BEB605bE72f1": 1,
"0x1fC2AEd128580391d59b1386e0C1Cdbe34Ce5f42": 1,
"0x021b3832C11306E43B850617175C41979E7cF7a0": 1,
"0x94aA21B1Da98314F1b8F9d8Aab6fFb2d0099A564": 1,
"0x82797703a0A5c8660d5BBEdBC6699AAD6BaAbba0": 1,
"0x720fFF4f23Db9644DaA375eD68bae5029E9751Cb": 1,
"0xb37c6c9Aa1Db5CDCf1A0AcB5044B794Cc6eE7f62": 1,
"0x5572E3f25F616a047a0312B985F66EDbee0e8162": 1,
"0xE1a87C087227Af0855F7dA73e7472Bd7A7b187A6": 1,
"0xb6936f5790cabC64772bc3b15ba9F7Ef73cCbf29": 1,
"0x67b81F681be19402e67fdAD28b465Ff798c27106": 1,
"0xCD982d2ed8D2Ef9a801fF6aECDec9Ab081896145": 1,
"0x474543b99438A978b39D39D8983723Eb5fF9196b": 1,
"0xbE4E1923DE1967D5A3882515E32aEEBfe7C51652": 1,
"0x338050301dF259aB1aEe79026e6E1BfF7B10C899": 1,
"0xfAb9a3D37999E12252b47468D2FFD4BE15936012": 1,
"0x355aa1B22400151DE9f67895c884E511DdE0700c": 1,
"0x5EdC4a692C4c2500d41C1BB863a113dBEF4abefD": 1,
"0xb94Bba3746d108257289a50490b9EEA526832A8b": 1,
"0x5D5704875129261E9E7a4Ba70302eBBbFa30541F": 1,
"0xB8FE58a20b24401D6A7392FfbBACE5C8C9a927eF": 1,
"0x4D9671b458a407F3A18EC702593e2788525cdFe4": 1,
"0x487E24007C9671ec794206E472B99AE8e0f4f7b2": 1,
"0xDdAfC3Bcde71b1f2f8220068C70555d257B4484B": 1,
"0x78807EfF628EEb122Ae8784fa5b3e1654dA22B25": 1,
"0x5567C85CBbe24c65C80783b520ea538552f47A88": 1,
"0xE66F686cA18f48Be51a7614D7226649D3451B792": 1,
"0x8a4d493882bA6F213C2Fd797640C26829b784D4b": 1,
"0xb2AE15a5e4cC0306b0533691b5cc21bcc031f76b": 1,
"0xfcE64A3197581665AAE3215d4CA33266beDeBD80": 1,
"0xC056790e2532a73bDd833f5BEf01015aB3A99f86": 1,
"0x5Ad7A7CBaB1602cc44c8050D40F4eA572D522649": 1,
"0x32d319Bfe769e4f09Dc65f3d9Ea1B5cEB39d9683": 1,
"0xc359e51b7D1905536b7309F4C83d81Fc09073787": 1,
"0xC0C5d9488914290564CE276880033f4166181Ee8": 1,
"0x7573e63E17cc36e7849867894dC6Bac3Aff29Da2": 1,
"0xE6a714E2c79bf12D60602895dfdDB33DD5B05E45": 1,
"0x74981d66164265c52976d06884116794fe41763f": 1,
"0xd32ed52e51Fc1089c3241c3a35bd8B6c8a192e33": 1,
"0x46Caa5f075C182C324CEc04792D1bcaF30B735BD": 1,
"0x71EE3D03F9e5E548C4A0EF5fB2903C7B2718a3f9": 1,
"0x5fa3E1c2d3006e93667333c18A118B03E61a5a46": 1,
"0x2151cf5dA5521d1A972b2Fabd376f6592537f0df": 1,
"0xb058b5Bd4bE74C4F6fe24926D46dFff1DE3D01da": 1,
"0xfeaa535d0C6f25c120709238f665ecEAE0772527": 1,
"0x61c884E885F2761b38B49A3e6624D02d7f8c3084": 1,
"0x22D9109ddce8ef80b247286c063ECC9b3ba10b5C": 1,
"0x2B2C869539188c28c202D086fE99c554493f72DD": 1,
"0x074fdC302F8D3C0E8B11C80F2A07BF2a3b8ca855": 1,
"0x12f0B9e47F368E5aef7a4aC367Ce1C0aA907cbf7": 1,
"0x9E6188494c8676E6c5492B6CD657d2857F540004": 1,
"0xBB7D198C65E2E632E001098e47A2FC94685CA2a4": 1,
"0x9daAaD3989D9D3149a8620a8Ef8Ca5db2CddFC38": 1,
"0x9305A3a61dc1169845a543D4b4bcF09C0729bc83": 1,
"0x86fEf6eC5320F6Cf9231f524aE89E198419CDC0F": 1,
"0xC84476F09D7F2D1A4042574FDB2fB179B885D330": 1,
"0x9E2bb7eD19b9e271557d83B8cA7457aF53E3641C": 1,
"0xe793eb572c1d75a83239C5dc7c432FE118C33998": 1,
"0x6F04833195De49aB7021c76F6C756ffa41CaD262": 1,
"0x8DB907BcB3A3B9a47536abd81dA90493DF1507d2": 1,
"0x9eA0C4BB61096ade0638E52bf0241deA5c2424aD": 1,
"0x01A2FF227D2392AA12A66F770593b33C76D95A2B": 1,
"0x381ed01130e3d81Ca6CDa1bBF09233D576560132": 1,
"0x83a922ae11A750ed1EBac24bBAC2b13a4491939d": 1,
"0x29CAa7a393cFE67576F81A8b77A22c7880aF5501": 1,
"0x719af2793aa4f0EBC6AEF1998b937BDb0EDA2E88": 1,
"0x6fccc6B6770569f3a634D14bF221e8a5D26a892b": 1,
"0x65bfCDE3AeD612795Ce2685118133137ED1407f4": 1,
"0x8Fb8fabD9f144CC2819827DE72ff4350160af55A": 1,
"0x32AB4262DF2C10ccC199F5f8E7332eD507eF3EA5": 1,
"0x1256E7992564AB22e332532472c916Bd8D1e1Ca7": 1,
"0x886478D3cf9581B624CB35b5446693Fc8A58B787": 1,
"0x5078110476Cd2Aa096160F2D988aE80aEaFB5Ae4": 1,
"0xE1d7eD9FFe4E9Ec526845BCC18449270Edc7875d": 1,
"0x0B356df8b4A7ed132278e7d60B7d7015753C1592": 1,
"0x46CAEE70a84A6Bb431B153EDA3529C1a68E7EA9D": 1,
"0xb81A8B14CA8cF7A5F8e9077557f5B33bDD7266A5": 1,
"0x823a3eF68A77D9eBf1d2805cd066c6422D1634eC": 1,
"0xeE03a0C6EFF922f3c96aD2C3e75017bFe1e3f6b8": 1,
"0x2a91C62dc4111d252BFe17F64b51969Ee9e982fc": 1,
"0x0Db5E7924d87d2EC27876882E7af911A58050Dd1": 1,
"0xBe769b04627613C8F3b30aBE20E6458c1DCB239B": 1,
"0x0A675600E32959c4159e46ea2354DCa2E58a2a49": 1,
"0x93F3DF4b4277344CeE99AcdC87BE9a62CF8Bf3Ae": 1,
"0xeB249f394dCA219CFc0b6066d804A21a55fDC2C9": 1,
"0xB84AAa2C983157520DC7fE9eB73E93d860d55d2f": 1,
"0x3ee99Cb74937D2ba9564149158DE298FC09c8C41": 1,
"0xb44ac514588BE99870220d12120815bc3D349507": 1,
"0x6744a9C6e3A9B8f7243Ace5B20D51a500fCd0353": 1,
"0xC54A6C3778016b06cBD126cCc3B5Bc06c5F666fb": 1,
"0xe2B9F0757a9E2813fae323aeFd89Ec8BE706104A": 1,
"0x78E96db9875d9857021f5072ce826006a3f4d1D1": 1,
"0x5C48dDef1B9cDb567AcA07dceB04837ef46cd239": 1,
"0x40748948177100f144b1126479924aAe1fdA1639": 1,
"0x7921c8Ab99Fc621b9681E422D63624D5671c1D47": 1,
"0x9064ABE13d219fF7482f1a434eaC8A8c8C9bcF30": 1,
"0x61cC11434dF265fE64b86c87d63709A7426D446E": 1,
"0xB8a92DA2416d59857Ae40f90fc16013108AcFaFB": 1,
"0x39AdD2b149b8Ef786dC63af67c76ef48Dbd193f3": 1,
"0x052826C486c8af2350D1aE148639bDaEdBcd2243": 1,
"0x3028cddA69Ff95ae53f77c0933706c79673AEE58": 1,

};

// concatenate
let claimlist_concat = Object.entries(claimlist).map(([k, v]) => ethers.utils.solidityPack(["address", "string"], [k, v.toString()]))
console.log("claimlist_concat", claimlist_concat)


// hash
const leafNodes = claimlist_concat.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
const rootHash = merkleTree.getRoot();

console.log('merkle root claimlist: 0x' + rootHash.toString('hex'))


// temp
//let temp_leaf = keccak256(ethers.utils.solidityPack(["address", "string"], ["0x05d9FDD098a0BA064C8296505febc6769f623EF0", "4"]))
//let temp_proof = merkleTree.getHexProof(temp_leaf)
//console.log("temp_proof: ", temp_proof)


export { claimlist, claimlist_concat, merkleTree, rootHash, leafNodes };
