import React, { Component } from 'react';
//import Boonkbots from '../abis/BoonkBots.json'
import $ from 'jquery';
import { Accordion, Card } from "react-bootstrap";
import roadmap from "./assets/roadmap.png"
import roadmap90 from "./assets/roadmap90.png"



class Home extends Component {

  async componentWillMount() {
    $('body').removeClass().addClass('genesis');
    $(document).ready(function() {

      // code for vids
      $("video").prop("volume", 0.6);
      $("video").prop('muted', true);
      $("video").click(function() {
        if ($(this).prop('muted')) {
          $("video").prop('muted', true);
          $(this).prop('muted', false);
        } else {
          $("video").prop('muted', true);
          $(this).prop('muted', true);
        }
      });

    });
  }

  render() {
    return (

    <div id="genesis" >
      <div className="d-flex align-items-center justify-content-center border-bottom maincontent">
         <div className="centered">
            <div className="row">
               <div className="col"  id="genesis_header">
                  <span>
                     <h1 className="bloom_mag text-responsive magenta">BOONKBOTS</h1>
                  </span>
               </div>
            </div>
            <div className="row  pt-2 pb-2">
               <div className="col">
                  <ul>
                     <li>
                        <h5>BEATS MEETS ROBOTS</h5>
                     </li>
                  </ul>

               </div>
            </div>

         </div>
      </div>





      <div className="row">
         <div className="col ">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/bb1.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
            </div>
            <span className="click">CLICK BOONKBOT FOR AUDIO</span>
         </div>
         <div className="col d-flex align-items-center">
            <div className="centered pt-5 pb-5">
               <br/><br/>
               <h3 className="magenta bloom_mag ">H3LL0 W0RLD.</h3> <br/>

               We make generative dance music. <br/><br/>
               We give metaverse live shows. <br/><br/>
               We are B00NKB0TS. <br/><br/>




            </div>
         </div>
      </div>



      <div className="row">
         <div className="col d-flex align-items-center">
            <div className="centered  pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-10 text-center">
                     <h3><span className="magenta bloom_mag ">BOONKBOTS G3N3S1S</span></h3>
                  </div>
               </div>
               <br/>
               G3N3S1S is the first B00NKB0TS NFT collection.

               <br/><br/>

               600 generative beats * bots

               <br/><br/>

               launched in november 2021

               <br/><br/>
               <small><a href="/genesis">more info</a> - <a href="https://opensea.io/collection/boonkbots" target="_blank" rel="noopener noreferrer">view the collection</a></small>



            </div>
         </div>
         <div className="col">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/bb2.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
               <br/><span className="click">CLICK BOONKBOT FOR AUDIO</span>
            </div>
         </div>
      </div>



      <div className="row mt-5 sunrise" id="sunrise">


         <div className="col">
            <div className="vid"><br/><br/>
               <video src="https://assets.boonkbots.xyz/bb1_sunrise.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
               <br/><span className="click">CLICK BOONKBOT FOR AUDIO</span>
               <br/><br/>
            </div>
         </div>
         <div className="col d-flex align-items-center">
            <div className="centered  pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-10 text-center">
                     <h3><span className="magenta_sunrise">&nbsp;BOONKBOTS&nbsp;</span><br/><span className="magenta">&nbsp;~SUNR1S3&nbsp;</span></h3>
                  </div>
               </div>
               <br/>
               The second chapter of the B00NK story.
               <br/><br/>

               launched in the summer of 2022.
               <br/><br/>

               <small><a href="/sunrise">more info</a> - <a href="https://opensea.io/collection/boonkbots-sunrise" target="_blank" rel="noopener noreferrer">view the collection</a></small>



            </div>
         </div>
      </div>

      <div className="row">
         <div className="col d-flex align-items-center">
            <div className="centered  pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-10 text-center">
                     <h3><span className="magenta bloom_mag ">DJ B00NKB0T</span></h3>
                  </div>
               </div>
               <br/>
               Meet the l337est DJ in the metaverse: DJ B00NKB0T.

               <br/><br/>

               DJ B00NKB0T is on a journey through the vast unknown, exploring all the different clubs of the metaverse.

               <br/><br/>
               <small><a href="https://twitter.com/boonkbots_xyz" target="_blank" rel="noopener noreferrer">follow DJ boonkbot</a></small>



            </div>
         </div>
         <div className="col">
            <div className="vid">
               <img src="https://assets.boonkbots.xyz/djboonkbot.jpg"/>
               <br/><span className="click">DJ B00NKB0T</span>
               <br/>
            </div>
         </div>
      </div>

      <br/>

      <div className="row">
         <div className="col ">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/boonktrax.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
            </div>
            <span className="click">CLICK BOONKBOT FOR AUDIO</span>
         </div>
         <div className="col d-flex align-items-center">
            <div className="centered pt-5 pb-5">
               <br/><br/>
               <h3 className="magenta bloom_mag ">B00NKTR4X</h3> <br/>

               B00NKTR4X is the home of our curated beatz. <br/><br/>

               Each record is released under a CC0 license. <br/><br/>

               Each release comes with an audiovisual NFT drop.
<br/><br/>
               <small><a href="/boonktrax">more info</a></small>

            </div>
         </div>
      </div>

      <br/>

      <div className="row">
         <div className="col d-flex align-items-center">
            <div className="centered  pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-10 text-center">
                     <h3><span className="magenta bloom_mag ">V1P</span></h3>
                  </div>
               </div>
               <br/>
              U a B00NKHEAD? <br/><br/>

              Go visit the <a href="/vip">V1P room</a><br/><br/>

              In the V1P, you can view all your B00NKB0TS and download a bunch of cool stuff.


            </div>
         </div>
         <div className="col">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/bb3.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
               <br/><span className="click">CLICK BOONKBOT FOR AUDIO</span>
            </div>
         </div>
      </div>


                  <div className="row">
                     <div className="col">
                        <div className="vid fullborder">
                           <video src="https://assets.boonkbots.xyz/bb5.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
                           <br/><span className="click">CLICK BOONKBOT FOR AUDIO</span>
                        </div>
                     </div>
                     <div className="col d-flex align-items-center justify-content-center">
                        <div className="centered  pt-5 pb-5">
                           <div className="row d-flex align-items-center justify-content-center">
                              <div className="col-10">
                                 <h3 className="magenta bloom_mag">ABOUT US</h3>
                              </div>
                           </div>
                           <br/><br/>
                           BOONKBOTS is the brainchild of electronic music duo <a href="https://twitter.com/mumbaiscience" target="_blank" rel="noopener noreferrer">Mumbai Science</a>.<br/><br/>
                           Come find us on <a href="https://discord.gg/Za2GEEmQhq" target="_blank" rel="noopener noreferrer">discord</a> or <a href="https:/twitter.com/boonkbots_xyz" target="_blank" rel="noopener noreferrer">twitter</a>. <br/><br/>
                        </div>
                     </div>
                  </div>



                  <div className="row d-flex align-items-center justify-content-center pt-5 pb-5">
                     <div className="col text-right d-none">
                        <h1><span role="img" aria-label="roadmap">🛣️</span></h1>
                     </div>
                     <div className="col-10 text-center">
                        <h3 className="magenta bloom_mag">ROADMAP</h3>
                     </div>
                     <div className="col text-left d-none">
                        <h1><span role="img" aria-label="roadmap">🛣️</span></h1>
                     </div>
                  </div>
                  <div className="row align-items-center justify-content-center horizontal_roadmap">
                     <div className="col-3 text-right">TODAY</div>
                     <div className="col-6">
                        <img width="100%" src={roadmap} alt="roadmap"/>
                        <div className="inprogress small">Music, Art, Merch, Vibes</div>
                     </div>
                     <div className="col-3">
                      {/*   <img className="invert" width="50" src={tomorrowland} alt="roadmap"/><br/><br/> */}
                        <div className="col-12 text-center">Tomorrow
                    {/*      <br/><span className='small'>nr1 dance festival in the world</span>*/}
                    </div>
                     </div>
                  </div>

                  <div className="row align-items-center justify-content-center vertical_roadmap">
                     <div className="w-100 text-center">
                        <h2>TODAY</h2>
                     </div>
                     <div className="row">
                        <div className="col">
                           <img width="100%" src={roadmap90} alt="roadmap"/>
                           <div className="inprogress">
                              <h4>Music, Art, Merch, Vibes</h4>
                           </div>
                        </div>
                     </div>
                     <div className="row row align-items-center justify-content-center">
                            {/*   <img className="invert" width="50" src={tomorrowland} alt="roadmap"/>*/}
                        <div className="col-12 text-center">
                           <h2>Tomorrow</h2>
                  {/*          <br/><span className='small'>nr1 dance festival in the world</span> */}
                        </div>
                     </div>
                  </div>





            <div className="row">
               <div className="col">
                  <div className="row d-flex align-items-center justify-content-center pt-5">
                     <div className="col text-right d-none">
                        <h1><span role="img" aria-label="rasing_hand">🙋</span></h1>
                     </div>
                     <div className="col-10">
                        <h3 className="cyan">FAQ</h3>
                     </div>
                     <div className="col text-left d-none">
                        <h1><span role="img" aria-label="rasing_hand">🙋</span></h1>
                     </div>
                  </div>
                  <div>
                     <Accordion>
                        <Card className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="0">
                              What's up with the word "Boonk"?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="0">
                              <Card.Body className="small">
                                 Boonk is adapted from the <span className="cyan">Belgian word "BOENK".</span><br/><br/>
                                 <span className="cyan">"Boenkmuziek"</span> = electronic dance music. 20 years ago it was mostly used as a negative term to signify electronic music - mostly used by older generations, rock fans and opponents of dance music, as in:
                                 <br/><br/>
                                 😠 "This is not real music, this is boenkmuziek"<br/>
                                 🤕 "My head hurts from this boenkmuziek".<br/>
                                 😡 "All young people listen to these days is boenkmuziek!"
                                 <br/><br/>
                                 However, the new generation - proponents of dance - claimed it and made it theirs. For instance, the shout <span className="l337intense">"boenkeuuuh"</span> - "let's boonk!" - means to enjoy dance music on a primal level, without bothering about social conventions or doubting about "is it cool to like this track?"
                                 <br/><br/>
                                 "Boenk" is also an onomatopoeia, the sound of the word is the same as its meaning:<br/>
                                 <span className="cyan">boonk boonk boonk boonk</span> = the kick/beat of dance music
                                 <br/><br/>
                                 🥳 <span className="cyan">LFB</span> - let's frigging boonk!
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                        <Card  className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="2">
                              How is the music created?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="2">
                              <Card.Body className="small">
                                 <span className="cyan">MIDI sequences</span> are generated <span className="cyan">programmatically</span>. These trigger each separate instrument - kick/snare/synth/etc. Each midi sequence contains randomness, within predefined boundaries.
                                 <br/><br/>
                                 Drums are sample-based. Synths are recorded live via some outboard gear. Ceel played around with some knobs during recording, ingesting a piece of his human soul into the boonkbot.
                                 <br/><br/>
                                 All loops are mixed/mastered through a processing chain which was set up per synth style.
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                        <Card className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="3">
                              How are the bots created?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="3">
                              <Card.Body className="small">
                                 Each bot is a <span className="cyan">unique combination</span> of head x shoulders x torso x legs x arms.
                                 <br/><br/>
                                 After procedurally assembling the bot, they are paired with a boonk, receive a color style and possibly some extra features such as club lights and smoke. Finally, they're animated and rendered.
                                 <br/><br/>
                                 Ceel also added some randomness here and there and some hidden traits for the h4rdc0r3.
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                     </Accordion>
                  </div>
               </div>
              </div>
            </div>
)
}}

export default Home;
