import React, { Component } from 'react';
import $ from 'jquery';
import { ethers } from 'ethers'
import {  claimlist, claimlist_concat, merkleTree, rootHash, leafNodes  }  from './claimlist.js'
const keccak256 = require('keccak256');


class Claim extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: '',
      address: '',
      claim_amount_max: 0,
      claim_current: 0,
      claim_amount_left: 0,
      merkleProof: "",
      totalSupply: 1337,
      max_supply: 1337
    };
  }

  async requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: this.props.CHAIN_ID }],
    });
    const address = await this.props.provider.getSigner().getAddress()
    this.setState({address : address});
    console.log("address: ", address)
    this.updateState();
  }

  async componentWillMount() {

    $('body').removeClass().addClass('sunrise');

    $(document).ready(function() {

      // code for vids
      $("video").prop("volume", 0.6);
      $("video").prop('muted', true);
      $("video").click(function() {
        if ($(this).prop('muted')) {
          $("video").prop('muted', true);
          $(this).prop('muted', false);
        } else {
          $("video").prop('muted', true);
          $(this).prop('muted', true);
        }
      });

    });

    this.updateState();
  }


  async updateState() {

    let status
    if (typeof window.ethereum == 'undefined') {

      // no metamask
      status = 0
      console.log("metamask not installed")

    } else {

      this.loadSupply();

      if (!this.state.address) {

        // not connected
        status = 1
        console.log("not connected")

      } else if (this.state.address === "") {

        // not connected
        status = 1
        console.log("not connected")

      } else if (!(Object.keys(claimlist).map(x => x.toUpperCase()).includes(this.state.address.toString().toUpperCase()))) {

        // not eligible
        status = 2
        console.log("connected but not eligible")

      } else {

        // eligible to claim
        status = 3
        console.log("connected and eligible")

        // get max claim amount
        let ks = Object.keys(claimlist).map(x => x.toUpperCase())
        let k = this.state.address.toString().toUpperCase()
        let i = ks.indexOf(k)
        let claim_amount_max = Object.values(claimlist)[i]
        console.log("claim_amount_max: ", claim_amount_max)

        // get already claimed
        console.log(this.state.address)
        const claim_amount_current_bn = await this.props.boonkbots.signer._boonklistClaimed(this.state.address)
        let claim_amount_current = claim_amount_current_bn.toNumber()
        console.log("claim_amount_current: ", claim_amount_current)

        // able to claim
        let claim_amount_left = claim_amount_max - claim_amount_current

        // update merkle MerkleProof
        let leaf = keccak256(ethers.utils.solidityPack(["address", "string"], [this.state.address, claim_amount_max.toString()]))
        let merkleProof = merkleTree.getHexProof(leaf)

        let rootHash_contract = await this.props.boonkbots.signer.merkleRootBoonklist()
        console.log("rootHash web: ", '0x' + rootHash.toString('hex'))
        console.log("rootHash contract ",rootHash_contract)
        console.log("leaf: ", leaf)
        console.log("proof", merkleProof)
        console.log("merkle tree verification: ", merkleTree.verify(merkleProof, leaf, rootHash_contract))

        this.setState(
            { claim_amount_max : claim_amount_max,
              claim_amount_current: claim_amount_current,
              claim_amount_left : claim_amount_left,
              merkleProof : merkleProof}
          );

        if (claim_amount_left == 0) {

          // no claims left
          status = 5
          console.log("no claims left")
        }

      }
    }

    console.log("status: ", status)
    this.setState(
        {status : status},
        function () {
          this.renderConnectText()
        }
      );

  }

  renderConnectText() {

    let status = this.state.status
    console.log(status)

    switch (status) {
      case 0:
        $("#metamask_install").show();
        $("#mintbutton").hide()
        $("#minted").hide()
        $("#connectbutton_submit").prop('disabled', true)
        $("#connectbutton_body").css('cursor', 'not-allowed')
        $("#claim_text").html("");
        $("#claim_address").hide();
        $("#claim_text_eligible").hide();
        $("#claim_text_noneleft").hide();
        $("#viewonopensea").hide();
        $("#supply").hide();
        break;
      case 1:
        $("#metamask_install").hide();
        $("#mintbutton").hide()
        $("#minted").show()
        $("#claim_text").hide();
        $("#claim_address").hide();
        $("#claim_text_eligible").hide();
        $("#claim_text_noneleft").hide();
        $("#viewonopensea").hide();
        break;
      case 2:
        $("#connectbutton_body").hide();
        $("#mintbutton").hide()
        $("#minted").hide()
        $("#claim_address").show();
        $("#claim_text").show();
        $("#claim_text").html("<h6><span>This address is <span className='red'>not eligible</span> for a free claim.</span></h6>");
        $("#claim_text").css('color', 'rgb(150,0,0)')
        $("#claim_text_eligible").hide();
        $("#claim_text_noneleft").hide();
        $("#viewonopensea").hide();
        $("#headline").hide();
        break;
      case 3:
        $("#metamask_install").hide();
        $("#mintbutton").show()
        $("#minted").hide()
        $("#connectbutton_body").hide();
        $("#claim_address").show();
        $("#claim_text_eligible").show();
        $("#claim_text_eligible").css('color', 'rgb(0,150,150)')
        $("#claim_text_eligible").show();
        $("#claim_text_noneleft").hide();
        $("#viewonopensea").hide();
        $("#headline").hide();
        break;
      case 4:
        $("#metamask_install").hide();
        $("#minted").hide();
        $("#mintbutton").hide()
        $("#claim_address").show();
        $("#claim_text_noneleft").hide();
        $("#claim_text_eligible").hide();
        $("#mintsuccess").show();
        $("#viewonopensea").show();
        $("#headline").hide();
        break;
      case 5:
        $("#metamask_install").hide();
        $("#mintbutton").hide()
        $("#connectbutton_body").hide();
        $("#minted").hide();
        $("#claim_address").show();
        $("#claim_text_noneleft").show();
        $("#claim_text_noneleft").css('color', 'rgb(150,0,0)')
        $("#claim_text_noneleft").show();
        $("#claim_text_eligible").hide();
        $("#viewonopensea").hide();
        $("#headline").hide();
        break;
      default:
        $("#metamask_install").show();
        $("#minted").hide();
    }
  }

  async loadSupply() {

    let totalSupply = await this.props.boonkbots.reader.totalSupply();
    let maxSupply = await this.props.boonkbots.reader.MAX_SUPPLY();
    totalSupply = parseInt(totalSupply);
    maxSupply = parseInt(maxSupply);
    this.setState({totalSupply : totalSupply});
    this.setState({max_supply : maxSupply});

  }

  async claim(amount, claim_amount_max, merkleProof) {

    //const price = ethers.utils.parseUnits(String(0.064 * amount), 'ether')
    await this.requestAccount()

    console.log("amount", amount)
    console.log("claim_amount_max", claim_amount_max)
    console.log("merkleProof", merkleProof)


    let estimatedGas = 100000 + 4000 * amount;
    try {
      const estimatedGasFromContract = await this.props.boonkbots.signer.estimateGas.claim(amount, claim_amount_max, merkleProof);
      estimatedGas = Math.ceil(estimatedGasFromContract.toNumber() * 1.1)

    } catch (error) {
      console.log('Could not estimate gas, using default estimation');
      console.log(error);
    }

    console.log("estimatedGas: ", estimatedGas);
    const transaction = await this.props.boonkbots.signer.claim(amount, claim_amount_max, merkleProof, {gasLimit :  estimatedGas});
    await transaction.wait();

    // refresh supply
    this.loadSupply();

    // success message
    const address = await this.props.provider.getSigner().getAddress()
    const link = "https://opensea.io/" + address;
    const html = "Claim Successful"
    const html2 = "<a className='l337' target='_blank' rel='noopener noreferrer' href="+link+">View on opensea</a>";

    $("#mintsuccess").html(html);
    $("#viewonopensea").html(html2);
    console.log("claimed")
    this.setState(
        {status : 4},
        function () {
          this.renderConnectText()
        }
      );
    document.getElementById('mintamount').value = '';

  }




  render() {
    return (



    <div id="sunrise" >
    <div className="maincontent d-flex align-items-center justify-content-center">

    <div className="">

      <div id="sunrise_header">
        <div className="centered">
            <div className="row">
               <div className="col">
                  <span>
                     <h1 className="text-responsive">BOONKBOTS</h1>
                     <h2 className="text-responsive"><span className="marker">~ SUNRISE </span></h2>
                  </span>
               </div>
             </div>
         </div>
      </div>


<div id = "headline">
<h4><br/><br/><span>FREE CLAIM: <span className="black">&nbsp;OPEN&nbsp;</span></span></h4>

<span className="smallPrint">Only for g3n3s1s holders</span>
</div>

      <div id="connectbutton_body" className="row mt-5">
        <main role='main' className='col-lg-12 d-flex text-center'>
          <div className='content mr-auto ml-auto'>
            <form onSubmit={(event) => {
              console.log("test");
              event.preventDefault();
              this.requestAccount();
            }}>
              <input
                id="connectbutton_submit"
                type='submit'
                className='btn btn-block btn-primary l3372'
                value='connect to wallet'
              />
            </form>
          </div>
        </main>
      </div>

      <div className="row mt-5">
         <div className="col">
      {/*            <ul>
               <li>
                  <h5>BEATS MEETS ROBOTS</h5>
               </li>
               <li>
                  <h5>600 UNIQUE NFT'S</h5>
               </li>
            </ul>
            */}
            <span id="claim_address">~ {this.state.address}</span>
            <span id="claim_text"></span>
            <span id="claim_text_eligible"><h6>You are able to claim <span className="cyan flicker_cyan">&nbsp;{this.state.claim_amount_left}&nbsp;</span> sunrise boonkbot{this.state.claim_amount_left > 1 ? 's' : ''}.</h6></span>
            <span id="claim_text_noneleft"><h6>You already claimed all your sunrise boonkbots.</h6></span>
            {/*
            <div id="eligible_line"><span className="smallPrint">Only elected addresses can mint at this time.</span></div>
            */}
         </div>
      </div>

      <div id="mintbutton">
         <div className='row mt-5'>
            <br/><br/>
            <main role='main' className='col-lg-12 d-flex text-center'>
               <div className='content mr-auto ml-auto'>
                  <form
                     id="mintform"
                     onSubmit={(event) => {
                     event.preventDefault();
                     const amount = event.target[0].value;
                     this.claim(amount, this.state.claim_amount_max, this.state.merkleProof);
                     }}>
                     <input
                        id="mintamount"
                        type='number'
                        min='1'
                        max={ this.state.claim_amount_left}
                        className='form-control mb-1'
                        ref={(input) => { let amount = input }}
                        style={{"text-align": "right"}}
                     />
                     <input
                        id="mint_submit"
                        type='submit'
                        className='btn btn-block btn-primary l3372'
                        value='CLAIM'
                        />
                  </form>
               </div>
            </main>
         </div></div>
         <div className="row mt-2">
            <div className="cyan col flicker_cyan" id="mintsuccess" ></div>
         </div>
         <div className="row mt-2">
            <div className="col" id="viewonopensea" ></div>
         </div>
          {/*
         <div className='row' id="minted">
            <div className='col pt-2'>
            <h6><span className = "magenta flicker_magenta">&nbsp;{this.state.max_supply - this.state.totalSupply}&nbsp;</span> / <span className = "magenta">&nbsp;{this.state.max_supply}&nbsp;</span></h6>
            available
            </div>
         </div>
         */}
         <div className='row' id="metamask_install">
            <div className='col pt-2'>
               <h6><span>install <a href='https://metamask.io/' target='_blank' rel='noopener noreferrer'>metamask</a> to mint</span>.</h6>
            </div>
         </div>


      </div>
         </div>

            <hr/>


            </div>
)
}}

export default Claim;
