import React, { Component } from 'react';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';

class Navigation extends Component {

  async requestAccount() {
    await window.ethereum.request({ method: 'eth_requestAccounts' })
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: this.props.CHAIN_ID }],
    });
    const address = await this.props.provider.getSigner().getAddress()
    console.log(address);
    this.setState({address : address});
    if (this.state.address !== "") {
      console.log(this.state.address)
      $("#connectbutton").hide();
      $("#account").html(this.state.address);
    }
  }

  render() {
    return(
      <nav className="navbar navbar-expand-md navbar-dark black-bg" id="boonknavbar">
        <div className="container-fluid">
          <small className="text-white navbar-brand abs smallPrint hide-mobile"><span id="account"></span></small>

          {/*
          <div id="connectbutton" className="navbar-brand abs hide-mobile">
            <main role='main' className='col-lg-12 d-flex text-center'>
              <div className='content mr-auto ml-auto'>
                <form onSubmit={(event) => {
                  console.log("test");
                  event.preventDefault();
                  this.requestAccount();
                }}>
                  <input
                    id="connectbutton_submit"
                    type='submit'
                    className='btn btn-block btn-primary l3372'
                    value='connect to wallet'
                  />
                </form>
              </div>
            </main>
          </div>
          */}

          <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>

            <ul className="navbar-nav navbar-collapse collapse" id="collapseNavbar">
              <NavLink to="/" style={{ textDecoration: 'none' }} ><li id="home_li"><a href="/" className="nav-link active">HOME</a></li></NavLink>
              <NavLink to="/genesis" style={{ textDecoration: 'none' }} ><li id="genesis_li"><a href="/genesis" className="nav-link active">G3N3S1S</a></li></NavLink>
              <NavLink to="/sunrise" style={{ textDecoration: 'none' }} ><li id="sunrise_li"><a href="/sunrise" className="nav-link active">SUNR1S3</a></li></NavLink>
              <NavLink to="/boonktrax" style={{ textDecoration: 'none' }}><li id="boonktracks_li"><a href="/boonktrax" className="nav-link active">B00NKTR4X</a></li></NavLink>
              {/* <NavLink to="/claim" style={{ textDecoration: 'none' }}><li id="boonktracks_li"><a href="/claim" className="nav-link active">CL41M</a></li></NavLink>*/}
              <NavLink to="/vip" style={{ textDecoration: 'none' }}><li id="boonktracks_li"><a href="/vip" className="nav-link active">V1P</a></li></NavLink>
            </ul>

        </div>
        </nav>
    )
  }
}

export default Navigation;
