import React, { Component } from 'react';
//import Boonkbots from '../abis/BoonkBots.json'
import bots_12_gif from "./assets/bots_12_gif.gif"
import bots_4_gif from "./assets/bots_4_gif.gif"
import $ from 'jquery';
import { Accordion, Card } from "react-bootstrap";



class Genesis extends Component {

  async componentWillMount() {
    $('body').removeClass().addClass('genesis');
    $(document).ready(function() {

      // code for vids
      $("video").prop("volume", 0.6);
      $("video").prop('muted', true);
      $("video").click(function() {
        if ($(this).prop('muted')) {
          $("video").prop('muted', true);
          $(this).prop('muted', false);
        } else {
          $("video").prop('muted', true);
          $(this).prop('muted', true);
        }
      });

    });
  }

  render() {
    return (

    <div id="genesis" >
      <div className="d-flex align-items-center justify-content-center border-bottom maincontent">
         <div className="centered">
            <div className="row">
               <div className="col"  id="genesis_header">
                  <span>
                     <h1 className="magenta bloom_mag text-responsive">BOONKBOTS<br/>G3N3S1S</h1>
                  </span>
               </div>
            </div>
            <div className="row  pt-2 pb-2">
               <div className="col">
                  <ul>
                     <li>
                        <h5>BEATS MEETS ROBOTS</h5>
                     </li>
                     <li>
                        <h5>600 UNIQUE NFT'S</h5>
                     </li>
                   {/*  <li>
                        <h5>0.064 ETH EACH</h5>
                     </li>  */}
                  </ul>
                  <h3><span className='l3373'>SOLD OUT</span></h3>
                  {/*
                  <div id="eligible_line"><span className="smallPrint">Only elected addresses can mint at this time.</span></div>
                  */}
               </div>
            </div>
            {/*
            <div id="mintbutton">
               <div className='row mt-5'>
                  <br/><br/>
                  <main role='main' className='col-lg-12 d-flex text-center'>
                     <div className='content mr-auto ml-auto'>
                        <form
                           id="mintform"
                           onSubmit={(event) => {
                           event.preventDefault();
                           const amount = event.target[0].value;
                           this.mint(amount);
                           }}>
                           <input
                              id="mintamount"
                              type='number'
                              max='4'
                              className='form-control mb-1'
                              placeholder='amount. max 4'
                              ref={(input) => { let amount = input }}
                           />
                           <input
                              id="mint_submit"
                              type='submit'
                              className='btn btn-block btn-primary l3372'
                              value='MINT'
                              />
                        </form>
                     </div>
                  </main>
               </div>
               <div className="green row pt-2">
                  <div className="green col" id="mintsuccess" ></div>
               </div>
               <div className='row' id="minted">
                  <div className='col pt-2'>
                     <h4>MINTED: <span className='l337'>{ this.props.state.totalSupply.toString() }</span> / 1337</h4>
                  </div>
               </div>
               <div className='row' id="metamask_install">
                  <div className='col pt-2'>
                     <h6><span>install <a href='https://metamask.io/' target='_blank' rel='noopener noreferrer'>metamask</a> to mint</span>.</h6>
                  </div>
               </div>
               <br/><br/>
            </div>



              <div className="row dancing_bots_12"><img src={bots_12_gif} alt="bots"/></div>
              <div className="row dancing_bots_4"><img src={bots_4_gif} alt="bots"/></div>

              */}
         </div>
      </div>





      <div className="row">
         <div className="col ">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/bb1.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
            </div>
            <span className="click">CLICK BOONKBOT FOR AUDIO</span>
         </div>
         <div className="col d-flex align-items-center">
            <div className="centered pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col text-right d-none">
                     <h1><span role="img" aria-label="boonk">🔊 </span></h1>
                  </div>
                  <div className="col-10 text-center">
                     <h3>WHAT IS A <span className="cyan">BOONK</span> ?</h3>
                  </div>
                  <div className="col text-left d-none">
                     <h1><span role="img" aria-label="boonk">🔊 </span></h1>
                  </div>
               </div>
               <br/><br/>
               A Boonk is another word for <span className="cyan">a beat</span>.
               <br/><br/>
               Our proprietary Boonks are also 16 bars of <span className="cyan">generatively</span> created dance music.
               <br/><br/>
               Each Boonk is <span className="cyan">unique</span>.
            </div>
         </div>
      </div>


      <hr/>


      <div className="row">
         <div className="col d-flex align-items-center">
            <div className="centered  pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col text-right d-none">
                     <h1><span role="img" aria-label="bot">🤖 </span></h1>
                  </div>
                  <div className="col-10 text-center">
                     <h3>WHAT IS A <span className="yellow ">BOONKBOT</span> ?</h3>
                  </div>
                  <div className="col text-left d-none">
                     <h1><span role="img" aria-label="bot">🤖 </span></h1>
                  </div>
               </div>
               <br/><br/>
               BoonkBots are the <span className="yellow">dancing avatars</span> that accompany each Boonk.
               <br/><br/>
               Boonkbots are based on a <span className="yellow">24x24x24</span> pixelated models - voxels.
               <br/><br/>
               Each BoonkBot is <span className="yellow">unique</span>.
            </div>
         </div>
         <div className="col">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/bb2.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
               <br/><span className="click">CLICK BOONKBOT FOR AUDIO</span>
            </div>
         </div>
      </div>


      <hr/>


      <div className="row pt-5">
         <div className="col">
            <div className="row d-flex align-items-center justify-content-center">
               <div className="col text-right d-none">
                  <h1><span role="img" aria-label="features">🦄</span></h1>
               </div>
               <div className="col-10 text-center">
                  <h3 className="magenta">F34TUR35</h3>
               </div>
               <div className="col text-left d-none">
                  <h1><span role="img" aria-label="features">🦄</span></h1>
               </div>
            </div>
            <br/><br/>
            <div className="row">
               <div className="col"> BoonkBots belong to one of five <span className="magenta">rarity</span> categories:<br/><br/>
               </div>
            </div>
            <div className="row">
               <div className="col p-3" style={{color: 'black', background: 'grey'}}>C0MM0N <br/>75%</div>
               <div className="col p-3" style={{color: 'black' ,background: 'cyan'}}>R4R3 <br/>20%</div>
               <div className="col p-3" style={{color: 'black', background: 'magenta'}}>3PIC <br/>4%</div>
               <div className="col p-3" style={{color: 'black' , background: 'yellow'}}>L3G3ND4RY <br/>0.8%</div>
               <div className="col p-3 l337bg">MYTH1C <br/>0.2%</div>
            </div>
            <br/>
            A boonkbot has many different traits: <br/><br/>
            </div>
            </div>
            <div className="row">
               <div className="col">
                  <h5 className="magenta"><span role="img" aria-label="boonk">🔊</span> B00NK <span role="img" aria-label="boonk">🔊</span></h5>
                  <ul>
                     <li>K1CK</li>
                     <li>SN4R3</li>
                     <li>H1H4T 1</li>
                     <li>H1H4T 2</li>
                     <li>SH4K3R</li>
                     <li>SYNTH</li>
                  </ul>
               </div>
               <div className="col">
                  <h5 className="magenta"><span role="img" aria-label="bot">🤖</span> B0T <span role="img" aria-label="bot">🤖</span></h5>
                  <ul>
                     <li>H34D</li>
                     <li>T0RS0</li>
                     <li>SH0ULD3RS</li>
                     <li>4RMS</li>
                     <li>L3GS</li>
                     <li>SCR33N</li>
                     <li>C0L0R STYL3</li>
                  </ul>
               </div>
               <div className="col">
                  <h5 className="magenta"><span role="img" aria-label="extra">✨</span> 3XTR4 <span role="img" aria-label="extra">✨</span></h5>
                  <ul>
                     <li>SMOK3</li>
                     <li>L1GHTS</li>
                  </ul>
               </div>
            </div>


            <hr/>

            <div className="row">
               <div className="col d-flex align-items-center">
                  <div className="centered  pt-5 pb-5">
                     <div className="row d-flex align-items-center justify-content-center">
                        <div className="col d-none">
                           <h1><span role="img" aria-label="heart">💚</span></h1>
                        </div>
                        <div className="col-10">
                           <h3><span className="green">BOONKBOTS CARE</span></h3>
                        </div>
                        <div className=" col d-none">
                           <h1><span role="img" aria-label="heart">💚</span></h1>
                        </div>
                     </div>
                     <br/><br/>
                     BoonkBots <span className="green">care about humans</span> - and have donated 25% of all proceeds of its genesis collection to charity.
                     <br/><br/>
                     BoonkBots <span className="green">care about the planet</span> - and all C02 emissions from minting were offset by our contributions to <a href="https://offsetra.com" target="_blank" rel="noopener noreferrer">OFFSETRA</a>.
                  </div>
               </div>
            </div>

            <hr/>


            <div className="row">
               <div className="col">
                  <div className="row d-flex align-items-center justify-content-center pt-5">
                     <div className="col text-right d-none">
                        <h1><span role="img" aria-label="rasing_hand">🙋</span></h1>
                     </div>
                     <div className="col-10">
                        <h3 className="cyan">FAQ</h3>
                     </div>
                     <div className="col text-left d-none">
                        <h1><span role="img" aria-label="rasing_hand">🙋</span></h1>
                     </div>
                  </div>
                  <div>
                     <Accordion>
                        <Card className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="0">
                              What's up with the word "Boonk"?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="0">
                              <Card.Body className="small">
                                 Boonk is adapted from the <span className="cyan">Belgian word "BOENK".</span><br/><br/>
                                 <span className="cyan">"Boenkmuziek"</span> = electronic dance music. 20 years ago it was mostly used as a negative term to signify electronic music - mostly used by older generations, rock fans and opponents of dance music, as in:
                                 <br/><br/>
                                 😠 "This is not real music, this is boenkmuziek"<br/>
                                 🤕 "My head hurts from this boenkmuziek".<br/>
                                 😡 "All young people listen to these days is boenkmuziek!"
                                 <br/><br/>
                                 However, the new generation - proponents of dance - claimed it and made it theirs. For instance, the shout <span className="l337intense">"boenkeuuuh"</span> - "let's boonk!" - means to enjoy dance music on a primal level, without bothering about social conventions or doubting about "is it cool to like this track?"
                                 <br/><br/>
                                 "Boenk" is also an onomatopoeia, the sound of the word is the same as its meaning:<br/>
                                 <span className="cyan">boonk boonk boonk boonk</span> = the kick/beat of dance music
                                 <br/><br/>
                                 🥳 <span className="cyan">LFB</span> - let's frigging boonk!
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                        <Card  className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="2">
                              How is the music created?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="2">
                              <Card.Body className="small">
                                 <span className="cyan">MIDI sequences</span> are generated <span className="cyan">programmatically</span>. These trigger each separate instrument - kick/snare/synth/etc. Each midi sequence contains randomness, within predefined boundaries.
                                 <br/><br/>
                                 Drums are sample-based. Synths are recorded live via some outboard gear. Ceel played around with some knobs during recording, ingesting a piece of his human soul into the boonkbot.
                                 <br/><br/>
                                 All loops are mixed/mastered through a processing chain which was set up per synth style.
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                        <Card className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="3">
                              How are the bots created?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="3">
                              <Card.Body className="small">
                                 Each bot is a <span className="cyan">unique combination</span> of head x shoulders x torso x legs x arms.
                                 <br/><br/>
                                 After procedurally assembling the bot, they are paired with a boonk, receive a color style and possibly some extra features such as club lights and smoke. Finally, they're animated and rendered.
                                 <br/><br/>
                                 Ceel also added some randomness here and there and some hidden traits for the h4rdc0r3.
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                     </Accordion>
                  </div>
               </div>
              </div>
            </div>
)
}}

export default Genesis;
