import React, { Component } from 'react';
import $ from 'jquery';



class Boonktrax extends Component {

  async componentWillMount() {
    $('body').removeClass().addClass('genesis');
  }

  render() {
    return(
      <div id="boonktrax">


        <div className="d-flex align-items-center justify-content-center border-bottom maincontent">
           <div className="centered">
              <div className="row">
                 <div className="col" id="genesis_header">
                    <span>
                       <h1 className="magenta bloom_mag text-responsive">BOONKTR4X</h1>
                    </span>
                 </div>
              </div>
              <div className="row  pt-2 pb-2">
                 <div className="col">

                 <div className="col">
                   B00NKTR4X is the home of <span className="l3373">BLOCKCHAIN BEATS</span>. <br/><br/>

                   Each record is released under a <span className="l337">CC0 license</span>. No rights reserved. Free to download, free to use, free to edit. <br/><br/>

                   Each release comes with an <span className="l3373">audiovisual NFT drop</span> - a piece of art, which grants access to a variety of 1337ness.
                 </div>

                 </div>
              </div>
           </div>
        </div>


        <div className="row pt-5">
          <div className="col">
            <h1 className="magenta">DJ B00NKB0T - 1337</h1>
             B00NKTRAX 0001<br/>
             29 march 2022
           </div>
         </div>
         <div className="row pt-5">
          <div className="col">
            <audio controls>
              <source src="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ BOONKBOT - 1337.mp3" type="audio/mp3"/>
                Your browser does not support the audio element.
              </audio>
         </div>
        </div>
        <div className="row pt-5">
          <div className="col">
           <span className="magenta">DOWNLOAD</span><br/>
           Music: <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ BOONKBOT - 1337.mp3" download>MP3</a> / <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ BOONKBOT - 1337.wav" download>WAV</a> / <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ BOONKBOT - 1337 - STEMS.zip" download>STEMS</a><br/>
           Viz: <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ B00NKB0T - 1337 - C0MM0N.zip" download>C0MM0N</a> / <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ B00NKB0T - 1337 - R4R3.zip" download>R4R3</a> / <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ B00NKB0T - 1337 - 3P1C.zip" download>3P1C</a> / <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ B00NKB0T - 1337 - L3G3ND4RY.zip" download>L3G3ND4RY</a> / <a href="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ B00NKB0T - 1337 - MYTH1C.zip" download>MYTH1C</a><br/>
         </div>
        </div>


        <div className="row pt-5">
          <div className="col vid">
          <br/>
            <a href="https://opensea.io/assets/0xa028e19f8fb745f614f8917413484fe87d98004c/5" target="_blank" rel="noopener noreferrer" class="w-inline-block">
              <video src="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ_BOONKBOT_-_1337_-_C0MM0N_small.mp4" muted="muted" playsinline="" autoplay="autoplay" preload="auto" loop> ur br0ws3r d035 n07 supp0r7 HTML5 v1d30. </video>
            </a>
            <span className="magenta"><br/>C0MM0N<br/>
            102/102
            </span>
          </div>
          <div className="col vid">
          <br/>
            <a href="https://opensea.io/assets/0xa028e19f8fb745f614f8917413484fe87d98004c/4" target="_blank" rel="noopener noreferrer" class="w-inline-block">
              <video src="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ_BOONKBOT_-_1337_-_R4R3_small.mp4" muted="muted" playsinline="" autoplay="autoplay" preload="auto" loop> ur br0ws3r d035 n07 supp0r7 HTML5 v1d30. </video>
            </a>
            <span className="cyan"><br/>R4R3<br/>
            60/60
            </span>
          </div>
          <div className="col vid">
          <br/>
            <a href="https://opensea.io/assets/0xa028e19f8fb745f614f8917413484fe87d98004c/3" target="_blank" rel="noopener noreferrer" class="w-inline-block">
              <video src="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ_BOONKBOT_-_1337_-_3P1C_small.mp4" muted="muted" playsinline="" autoplay="autoplay" preload="auto" loop> ur br0ws3r d035 n07 supp0r7 HTML5 v1d30. </video>
            </a>
            <span className="red"><br/>3P1C<br/>
            42/42
            </span>
          </div>
          <div className="col vid">
          <br/>
            <a href="https://opensea.io/assets/0xa028e19f8fb745f614f8917413484fe87d98004c/2" target="_blank" rel="noopener noreferrer" class="w-inline-block">
              <video src="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ_BOONKBOT_-_1337_-_L3G3ND4RY_small.mp4" muted="muted" playsinline="" autoplay="autoplay" preload="auto" loop> ur br0ws3r d035 n07 supp0r7 HTML5 v1d30. </video>
            </a>
            <span className="silver"><br/>L3G3ND4RY<br/>
            11/11
            </span>
          </div>
          <div className="col vid">
          <br/>
            <a href="https://opensea.io/assets/0xa028e19f8fb745f614f8917413484fe87d98004c/1" target="_blank" rel="noopener noreferrer" class="w-inline-block">
              <video src="https://assets.boonkbots.xyz/trax/DJ BOONKBOT - 1337/DJ_BOONKBOT_-_1337_-_MYTH1C_small.mp4" muted="muted" playsinline="" autoplay="autoplay" preload="auto" loop> ur br0ws3r d035 n07 supp0r7 HTML5 v1d30. </video>
            </a>
            <span className="yellow"><br/>MYTH1C<br/>
            5/5
            </span>
          </div>
        </div>


        <div className="backtohome pt-5">
        <hr/>
          Back to <a href="/">Home</a>
        </div>
        <br/>
    </div>
    )
  }
}

export default Boonktrax;
