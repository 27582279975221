import React, { Component } from 'react';
//import Boonkbots from '../abis/BoonkBots.json'
import roadmap from "./assets/roadmap.png"
import roadmap90 from "./assets/roadmap90.png"
import $ from 'jquery';
import { ethers } from 'ethers'
import { Accordion, Card } from "react-bootstrap";


class Sunrise extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: '',
      address: '',
      mint_amount_max: 0,
      mint_current: 0,
      mint_amount_left: 0,
      merkleProof: "",
      totalSupply: 1337,
      max_supply: 1337,
      price: 0.064
    };
  }


  async componentWillMount() {

    $('body').removeClass().addClass('sunrise');

    $(document).ready(function() {

      // code for vids
      $("video").prop("volume", 0.6);
      $("video").prop('muted', true);
      $("video").click(function() {
        if ($(this).prop('muted')) {
          $("video").prop('muted', true);
          $(this).prop('muted', false);
        } else {
          $("video").prop('muted', true);
          $(this).prop('muted', true);
        }
      });

    });

  }


  render() {
    return (



    <div id="sunrise" >
    <div className="maincontent d-flex align-items-center justify-content-center">

    <div className="">

      <div id="sunrise_header">
        <div className="centered">
            <div className="row">
               <div className="col">
                  <span>
                     <h1 className="text-responsive">BOONKBOTS</h1>
                     <h2 className="text-responsive"><span className="marker">~ SUNR1S3 </span></h2>
                  </span>
               </div>
             </div>
         </div>
      </div>


      <div className="row mt-5">
         <div className="col">

         {/*
                <ul>
               <li>
                  <h5>BEATS MEETS ROBOTS</h5>
               </li>
               <li>
                  <h5>310 UNIQUE NFT'S</h5>
               </li>
            </ul>
*/}
<br/>
            <h3><span id="claim_text" className="flicker_cyan cyan">&nbsp;SOLD OUT&nbsp;</span></h3><br/>

         </div>
      </div>


      </div>
         </div>






      <div className="row">
         <div className="col ">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/bb4_sunrise.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
            </div>
            <span className="click">CLICK BOONKBOT FOR AUDIO</span>
         </div>
         <div className="col d-flex align-items-center">
            <div className="centered pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-10 text-center">
                     <h3 className="marker">WHAT IS A BOONK?</h3>
                  </div>
               </div>
               <br/><br/>
               A Boonk is another word for a beat.
               <br/><br/>
               Boonks are 16 bars of generatively created dance music.
               <br/><br/>
               Each Boonk is unique.
            </div>
         </div>
      </div>


      <hr/>


      <div className="row">
         <div className="col d-flex align-items-center">
            <div className="centered  pt-5 pb-5">
               <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-10 text-center">
                     <h3 className="marker">WHAT IS A BOONKBOT?</h3>
                  </div>
               </div>
               <br/><br/>
               BoonkBots are the dancing avatars that accompany each Boonk.
               <br/><br/>
               Boonkbots are based on 24x24x24 pixelated models - voxels.
               <br/><br/>
               Each BoonkBot is unique.
            </div>
         </div>
         <div className="col">
            <div className="vid">
               <video src="https://assets.boonkbots.xyz/bb1_sunrise.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
               <br/><span className="click">CLICK BOONKBOT FOR AUDIO</span>
            </div>
         </div>
      </div>


      <hr/>


      <div className="row pt-5">
         <div className="col">
            <div className="row d-flex align-items-center justify-content-center">
               <div className="col-10 text-center">
                  <h3 className="marker">F34TUR35</h3>
               </div>
            </div>
            <br/><br/>
            <div className="row">
               <div className="col"> BoonkBots belong to one of five rarity categories:<br/><br/>
               </div>
            </div>
            <div className="row">
               <div className="col p-3" style={{color: 'white', background: 'grey'}}>C0MM0N <br/>75%</div>
               <div className="col p-3" style={{color: 'white' ,background: 'rgb(0,180,180)'}}>R4R3 <br/>20%</div>
               <div className="col p-3" style={{color: 'white', background: 'rgb(180,0,180)'}}>3PIC <br/>4%</div>
               <div className="col p-3" style={{color: 'black' , background: 'rgb(255,255,0)'}}>L3G3ND4RY <br/>0.8%</div>
               <div className="col p-3 l337bg">MYTH1C <br/>0.2%</div>
            </div>
            <br/>
            A sunrise boonkbot has many different traits: <br/><br/>
            </div>
            </div>
            <div className="row">
               <div className="col">
                  <h5 className="cyan">B00NK</h5>
                  <ul>
                     <li>K1CK</li>
                     <li>SN4R3</li>
                     <li>H1H4T 1</li>
                     <li>H1H4T 2</li>
                     <li>P3RCUSS10N</li>
                     <li>M3LODY STYL3</li>
                  </ul>
               </div>
               <div className="col">
                  <h5 className="cyan">B0T</h5>
                  <ul>
                     <li>H34D</li>
                     <li>T0RS0</li>
                     <li>SH0ULD3RS</li>
                     <li>4RMS</li>
                     <li>L3GS</li>
                     <li>SCR33N</li>
                     <li>C0L0R STYL3</li>
                  </ul>
               </div>
               <div className="col">
                  <h5 className="cyan">3XTR4</h5>
                  <ul>
                     <li>0C34N</li>
                     <li>R41NB0W</li>
                     <li>F0R3GR0UND</li>
                     <li>B4CKGROUND</li>
                  </ul>
               </div>
            </div>


            <hr/>



            <div className="row">
               <div className="col">
                  <div className="vid fullborder">
                     <video src="https://assets.boonkbots.xyz/bb2_sunrise.mp4" muted="muted" playsInline="" autoPlay="autoplay" preload="auto" loop> UR BR0WS3R D03S N0T SUPP0RT HTML5 V1D30. </video>
                     <br/><span className="click">CLICK BOONKBOT FOR AUDIO</span>
                  </div>
               </div>
               <div className="col d-flex align-items-center justify-content-center">
                  <div className="centered  pt-5 pb-5">
                     <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-10">
                           <h3 className="marker">SUNR1S3</h3>
                        </div>
                     </div>
                     <br/><br/>
                     B00NKB0TS SUNRISE marks the second chapter of the B00NK STORY. It is the successor of <a href="/genesis">B00NKB0TS G3N3S1S</a>.
                     <br/><br/>
                     Every holder of a G3N3S1S B00NKB0T was able to claim a SUNR1S3 bot for free, during the summer of 2022.
                     <br/><br/>
                     View the sunrise collection on <a href="https://opensea.io/collection/boonkbots-sunrise" target="_blank" rel="noopener noreferrer">opensea</a>.
                     </div>
               </div>
            </div>


            <hr/>

            <div className="row">
               <div className="col">
                  <div className="row d-flex align-items-center justify-content-center pt-5">
                     <div className="col text-right d-none">
                        <h1><span role="img" aria-label="rasing_hand">🙋</span></h1>
                     </div>
                     <div className="col-10">
                        <h3 className="marker">FAQ</h3>
                     </div>
                     <div className="col text-left d-none">
                        <h1><span role="img" aria-label="rasing_hand">🙋</span></h1>
                     </div>
                  </div>
                  <div><br/>
                     <Accordion>
                        <Card className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="0">
                              What's up with the word "Boonk"?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="0">
                              <Card.Body className="small">
                                 Boonk is adapted from the <span className="cyan">Belgian word "BOENK".</span><br/><br/>
                                 <span className="cyan">"Boenkmuziek"</span> = electronic dance music. 20 years ago it was mostly used as a negative term to signify electronic music - mostly used by older generations, rock fans and opponents of dance music, as in:
                                 <br/><br/>
                                 😠 "This is not real music, this is boenkmuziek"<br/>
                                 🤕 "My head hurts from this boenkmuziek".<br/>
                                 😡 "All young people listen to these days is boenkmuziek!"
                                 <br/><br/>
                                 However, the new generation - proponents of dance - claimed it and made it theirs. For instance, the shout <span className="l337intense">"boenkeuuuh"</span> - "let's boonk!" - means to enjoy dance music on a primal level, without bothering about social conventions or doubting about "is it cool to like this track?"
                                 <br/><br/>
                                 "Boenk" is also an onomatopoeia, the sound of the word is the same as its meaning:<br/>
                                 <span className="cyan">boonk boonk boonk boonk</span> = the kick/beat of dance music
                                 <br/><br/>
                                 🥳 <span className="cyan">LFB</span> - let's frigging boonk!
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                        <Card  className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="2">
                              How is the music created?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="2">
                              <Card.Body className="small">
                                 <span className="cyan">MIDI sequences</span> are generated <span className="cyan">programmatically</span>. These trigger each separate instrument - kick/snare/synth/etc. Each midi sequence contains randomness, within predefined boundaries.
                                 <br/><br/>
                                 Drums are sample-based. Synths are recorded live via some outboard gear. Ceel played around with some knobs during recording, ingesting a piece of his human soul into the boonkbot.
                                 <br/><br/>
                                 All loops are mixed/mastered through a processing chain which was set up per synth style.
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                        <Card className="faq">
                           <Accordion.Toggle as={Card.Header} eventKey="3">
                              How are the bots created?
                           </Accordion.Toggle>
                           <Accordion.Collapse eventKey="3">
                              <Card.Body className="small">
                                 Each bot is a <span className="cyan">unique combination</span> of head x shoulders x torso x legs x arms.
                                 <br/><br/>
                                 After procedurally assembling the bot, they are paired with a boonk, receive a color style and possibly some extra features such as club lights and smoke. Finally, they're animated and rendered.
                                 <br/><br/>
                                 Ceel also added some randomness here and there and some hidden traits for the h4rdc0r3.
                              </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                     </Accordion>
                  </div>
               </div>
              </div>
            </div>
)
}}

export default Sunrise;
