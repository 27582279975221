import React, { Component } from 'react';
import twitter_icon from "./assets/icons/twitter.svg"
import discord_icon from "./assets/icons/discord.svg"
import opensea_icon from "./assets/icons/opensea.png"

class Footer extends Component {

  render() {
    return(
      <div className="footer">
                           <a href="https://twitter.com/boonkbots_xyz" target="_blank" rel="noopener noreferrer" className="img_link w-inline-block"><img src={twitter_icon} loading="lazy" alt="Twitter Logo" className="filter-white"/></a>
                           <a href="https://discord.gg/Za2GEEmQhq" target="_blank" rel="noopener noreferrer" className="img_link w-inline-block"><img src={discord_icon} loading="lazy" alt="Discord Logo" className="filter-white"/></a>
                           <a href="https://opensea.io/collection/boonkbots" target="_blank" rel="noopener noreferrer" className="img_link w-inline-block"><img src={opensea_icon} loading="lazy" alt="OpenSea Logo"/></a>
                        </div>
    )
  }
}

export default Footer;
